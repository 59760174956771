@font-face {
  font-family: "Flexicar";
  src: url("../fonts/Gotham.ttf");
}

.Cont {
  margin: 0 5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Flexicar" !important;
  line-height: 1.8 !important;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.right {
  float: right;
}

.left {
  float: left;
}

.flex {
  display: flex;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Home-Box {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 24px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
}

.Stock-Thumb {
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 7px;
}

.circle {
  border-radius: 70%;
  background-color: #fff;
}

.circulo {
  width: 15px;
  height: 15px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  margin: auto;
}

.circulo-grande {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 3px;
  border-style: solid;
  display: inline-block;
  margin: auto;
  margin-bottom: 4px !important;
}

.verde_activo {
  background-color: #00a99d !important;
  border-color: #00a99d !important;
}

.verde_inactivo {
  /* background-color: #e8f5e9 !important; */
  border-color: #00a99d !important;
}

.rojo_activo {
  background-color: #ed1c24 !important;
  border-color: #ed1c24 !important;
}

.rojo_inactivo {
  /* background-color: #ffcdd2 !important; */
  border-color: #ed1c24 !important;
}

.amarillo_activo {
  background-color: #fbb03b !important;
  border-color: #fbb03b !important;
}

.amarillo_inactivo {
  /* background-color: #fff8e1 !important; */
  border-color: #fbb03b !important;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration-line: underline;
}

.form-control:focus {
  border-color: #ff5d0e !important;
  box-shadow: 0 0 0 0.2rem rgba(253, 172, 79, 0.473) !important;
}

.flexicarColor {
  color: #ff5d0e;
}

.flexicarCRMoldColor {
  color: #fe9800;
}

.tittle {
  padding-left: 16px !important;
  border-left: 4px solid #f34c36;
  font-weight: bold;
  color: white;
}

.tittle-rigth {
  display: flex;
  margin-left: auto;
}

.tittle_sub {
  padding-left: 16px !important;
  border-left: 4px solid #f34c36;
  font-weight: bold;
  color: black;

  @media (max-width: 767px) {
    display: block;
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) {
.btn-flexicar-mobile {
    width: 100%;
    margin: 4px 15px;
   }
 }

.btn-flexicar {
  padding: 3px 10px;
  color: #fff !important;
  background-color: #ff5d0e !important;
  border-color: #ff5d0e !important;
}

.btn-flexicar:disabled {
  padding: 3px 10px;
  color: #FFF !important;  
  border-color: #BCBCBC !important;
  background-color:#BCBCBC !important;
}

.btn-flexicar:disabled:hover {
  padding: 3px 10px;
  color: #FFF !important;  
  border-color: #BCBCBC !important;
  background-color:#BCBCBC !important;
}

.btn-flexicar:hover {
  background-color: rgb(219, 73, 0) !important;
  border-color: rgb(219, 73, 0) !important;
}

.btn-flexicar-orange {
  padding: 3px 10px;
  color: #fff !important;
  background-color: #ff5d0e !important;
  border-color: #ff5d0e !important;
  border-radius: 4px;
  font-weight: bold;
}

.btn-flexicar-orange:hover {
  background-color: rgb(219, 73, 0) !important;
  border-color: rgb(219, 73, 0) !important;
}

.btn-flexicar-orange:disabled {
  padding: 3px 10px;
  color: #FFF !important;  
  border-color: #BCBCBC !important;
  background-color:#BCBCBC !important;
}

.btn-flexicar-inverse-size-s {
  padding: 3px 10px;
  color: #ff5d0e !important;
  background-color: #ffffff !important;
  border-color: #ff5d0e !important;
}

.btn-flexicar-inverse-size-s-active {
  color: #ffffff !important;
  background-color: #ff5d0e !important;
  border-color: #ff5d0e !important;
}

.btn-flexicar-inverse-size-s:hover {
  color: #ffffff !important;
  background-color: rgb(219, 73, 0) !important;
  border-color: rgb(219, 73, 0) !important;
}

.btn-flexicar-blue-mini {
  margin: auto 2px;
  padding: 1px 5px;
  color: #fff !important;
  background-color: rgb(0, 123, 255) !important;
  border-color: rgb(0, 123, 255) !important;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
}

.btn-flexicar-green-mini {
  margin: auto 2px;
  padding: 1px 5px;
  color: #fff !important;
  background-color: green !important;
  border-color: green !important;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
}

.btn-flexicar-red-mini {
  margin: auto 2px;
  padding: 1px 5px;
  color: #fff !important;
  background-color: red !important;
  border-color: red !important;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
}

.btn-flexicar-red {
  padding: 3px 10px;
  color: #fff !important;
  background-color: red !important;
  border-color: red !important;
}

.btn-flexicar-red:hover {
  background-color: rgb(190, 0, 0) !important;
  border-color: rgb(190, 0, 0) !important;
}

.btn-flexicar-blue {
  padding: 3px 10px;
  color: #fff !important;
  background-color: rgb(0, 123, 255) !important;
  border-color: rgb(0, 123, 255) !important;
}

.btn-flexicar-blue:hover {
  background-color: rgb(0, 123, 255) !important;
  border-color: rgb(0, 123, 255) !important;
}

.btn-flexicar-inverse {
  color: #D34600;
  background-color: #ffffff !important;
  border-color: #ff5d0e !important;
  font-weight: bold;
}

.btn-flexicar-inverse-active {
  margin: 10px;
  padding: 7px;
  color: #ffffff !important;
  background-color: #ff5d0e !important;
  border-color: #ff5d0e !important;
}

.btn-flexicar-inverse:hover {
  color: #ffffff !important;
  background-color: rgb(219, 73, 0) !important;
  border-color: rgb(219, 73, 0) !important;
}

.btn-flexicar-inverse:disabled {
  color: #FFF !important;
  border-color: #BCBCBC !important;
  background-color: #BCBCBC !important;
  cursor: not-allowed;
}

.container {
  width: 100% !important;
}

.row {
  width: 100% !important;
}

.right-align {
  text-align: right;
}

.green-text {
  color: #4caf50;
}

.blue-text {
  color: #2196f3 !important;
}

.chat-message {
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin: 2px 5px 20px;
}

.chat-top {
  padding: 2px 8px;
  border-radius: 10px 10px 0px 0px;
  color: white;
  font-weight: bold;
  background-color: #888888;
}

.chat-bottom {
  padding: 2px 8px;
  border-radius: 0px 0px 10px 10px;
  background-color: #cccccc59;
}

.bottom-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 15px;
  z-index: 100;
}

.back-btn {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.toast_green {
  background-color: #dfffdd !important;
  border: 1px solid #7cff76 !important;
}

.toast_yellow {
  background-color: #fffedd !important;
  border: 1px solid #f7ff76 !important;
}

.toast_red {
  background-color: #ffdddd !important;
  border: 1px solid #ff7676 !important;
}

.toast_blue {
  background-color: #dde7ff !important;
  border: 1px solid #76a8ff !important;
}

.toast-link {
  text-decoration: none !important;
  color: black;
  font-weight: 800;
}

/* MENU COMPONENT STYLE */
.navbar-light .navbar-nav .nav-link {
  color: #fe9800 !important;
  padding: 8 0 !important;
}

.navbar-light .navbar-text a {
  color: #fe9800 !important;
  text-decoration: none;
}

@media only screen and (min-width: 600px) {
  .navbar-collapse {
    display: flex;
    justify-content: flex-end;
  }
}

.modal-xxl {
  margin: 1.75rem auto !important;
  max-width: 80% !important;
}

/* .modal-open {
  overflow: scroll !important;
} */

/* Box Container */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-container>div {
  background-color: #f1f1f1;
  width: 200px;
  margin: 20px 7px;
  padding: 25px 0;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  -webkit-box-shadow: 6px 9px 11px -2px rgba(166, 166, 166, 0.47);
  -moz-box-shadow: 6px 9px 11px -2px rgba(166, 166, 166, 0.47);
  box-shadow: 6px 9px 11px -2px rgba(166, 166, 166, 0.47);
}

/* Font Sizes */
.fontXS {
  font-size: 10px;
}

.fontS {
  font-size: 12px;
}

.fontM {
  font-size: 15px;
}

.fontL {
  font-size: 25px;
}

.fontXL {
  font-size: 30px;
}

.fontXXL {
  font-size: 40px;
}

.columns3 {
  -webkit-column-width: 300px;
  -moz-column-width: 300px;
  column-width: 300px;
  background-color: #f8f9fa;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerY {
  display: flex;
  align-items: center;
}

.centerX {
  display: flex;
  justify-content: center;
}

/* Print Styles */
@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}
