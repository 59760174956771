.customDatePickerWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        margin-bottom: 0;
    }
}

.reactDatepickerCloseIcon::after {
    cursor: pointer;
    color: #666;
    background-color: transparent;
    height: 22px;
    width: 22px;
    padding: 2px;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}