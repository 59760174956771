.container2 {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.filtersContent {
  padding: 10px;
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
}

.fleft {
  float: left;
}

.fright {
  float: right;
  text-align: center;
}

.startRow {
  padding-top: 15px !important;
}

.titleCalendar{
  display: flex ;
  flex-direction: column;
}
.calendarMounth {
  color: black;
  font-size: 24px;
  padding-left: 15px;
  align-items: center;
  margin: auto 10px;
}
.totalBookings{
  align-self:flex-end;
  font-size: 1.2rem;
  margin: 0 1rem 0 0;
}
.buttonsCalendar{
  display: flex;
  gap: 0.5rem;
  margin: 0 1rem 0 0;
}
.flexBetween{
  display: flex;
  justify-content: space-between;
}
.calendarArrows {
  color: white;
  margin: 0 5px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}
.backgroundArrows {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #BCBCBC;
  width: 36px;
  height: 36px;
}
Col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

@media (min-width: 700px) {
  .calendarHeaderTitle {
    font-size: 14px;
  }

  .calendarContent {
    min-height: 500px;
    border-right: 1px solid #0a344460;
    border-left: 1px solid #0a344460;
  }
  .calendarHeader {
    background: #0a3444;
    color: white;
    height: 50px;
    margin: 0;
    padding: 0
  }
  .startCol {
    padding-top: 20px !important;
  }
  .mobile {
    display: none;
  }

}

@media (max-width: 700px) {
  .calendarHeaderTitle {
    margin-top: 15px;
  }
  .calendarArrows {
    font-size: 12px;
  }
  .backgroundArrows {
    width: 32px;
    height: 32px;

  }
  .desktop {
    display: none;
  }
  .calendarMounth{
    font-size: 20px;
  }
}

.calendarBlock {
  margin: 3px 1px;
  padding: 3px;
  color: white;
  border-radius: 15px;
  align-items: center;
}


.calendarCol {
  border-right: 1px solid rgba(128, 128, 128, 0.479);
}

.numberCircle {
  font-weight: bold;
  border-radius: 50%;
  height: 15px !important;
  width: 35px !important;
  padding: 8px !important;
  margin-left: 5px;
  background: rgb(255, 113, 47);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 12px;
}

.numberTotalCircle {
  border-radius: 50%;
  padding: 4px 10px;
  margin-left: 5px;
  background: rgb(255, 113, 47);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 12px;
}

.white {
  color: white;
}
.bold {
  font-weight: bold;
}
.capitalize{
  text-transform: capitalize;
}

.legend {
  display: grid;
  list-style: none;
  padding: 10px 0px;
}

.legend span {
  border: 1px solid #ccc;
  border-radius: 9px;
  float: left;
  width: 40px;
  height: 25px;
  margin: 2px 5px;
}

.legend p {
  margin: 2px auto;
}

.pagado {
  background-color: #f0a23c;
}
.reservado_online {
  background-color: #292929a6;
}

.reservado {
  background-color: #ffc881;
}

.engestion {
  background-color: #82a2dd;
}

.cancelado {
  background-color: #ff6464;
}

.no_reservations {
  background-color: #ffffff;
  color: rgb(255, 113, 47);
  border: 1px solid rgb(255, 113, 47);
}
