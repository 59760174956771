.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 150px;
}
.uploadBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.uploadInput {
  display: none;
}

.iconBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.buttonAccept {
  box-shadow: inset 0px 1px 3px 0px #3dc21b;
  background: linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%);
  background-color: #44c767;
  border-radius: 5px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  padding: 2px 20px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #2f6627;
}

.buttonCancel {
  box-shadow: inset 0px 1px 3px 0px #f29c93;
  background: linear-gradient(to bottom, #fe1a00 5%, #ce0100 100%);
  background-color: #fe1a00;
  border-radius: 5px;
  border: 1px solid #d83526;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  padding: 2px 20px;
  text-decoration: none;
  text-shadow: 0px -1px 0px #b23e35;
}

.error {
  color: red;     
  text-align: center;   
  margin-top: 10px;     
  margin-bottom: 10px;  
  font-size: 14px;        
  font-weight: bold;      
}


