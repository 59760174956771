.vehicle_additional_info_form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .vehicle_additional_info_form__fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1rem;

        >* {
            width: calc(50% - 1rem);
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 1.25rem;

            >* {
                width: 100%;
            }
        }
    }
}