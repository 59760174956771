
.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
    margin: 0.25em 0;

}
.flex {
    padding-bottom: 10px;
    display: flex;
}
.wrapper.text-center.sub-menu {
    margin-bottom: 20px;
}
.filter {
    margin-bottom: 10px;
}
.arrow{
        color: #f34c36;
    margin-left: 5px;
}
.create{
  
    display: block;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.back{
  
    display: block;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.print{
   
    display: block;
    color:white !important;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.photo{
   
   
    color:white !important;
    border-radius: 100%  !important;
   
    margin-right: 5px;
}
.contrato{
   
    display: none !important;
    color:white !important;
    border-radius: 100%  !important;

    margin-right: 5px;
}
 input.floatingInput {
    border: none;
    border-bottom: solid 1px #9e9e9e69;
    border-radius: 0;
     &:focus {
    border-color: none;
    box-shadow: none !important;
    border-bottom:  solid 1px  #f34c36;
    outline: 0; 
    

 }
}

.floatingLabel {
  position:  relative; 
  margin-bottom:  10px; 
  .react-dropdown-select {
          border-bottom: 1px solid #ccc;
  }
  label {
      font-size:0rem;
    position:  absolute; 
    top: calc(50% - 10px);
    left:  12px; 
    opacity:  0; 
    transition:  all .3s ease;
    color:#9e9e9e
  }
  input:not(:placeholder-shown) {
    padding:  28px 10px 12px 10px; 
  }
  input:not(:placeholder-shown) + label {
    transform:  translateY(-10px); 
    opacity:  1; 
    font-size:0.7rem;
  }
  .filter input:not(:placeholder-shown) {
    padding:  5px 5px 0 0 ; 
  }
  .filter input:not(:placeholder-shown) + label {
    transform:  translateY(-20px); 
    opacity:  1; 
  }
  .filter span{
      padding-top: 12px;
  }
 .select{
    font-size: 0.7rem;
    position: absolute;
    top: calc(12% - 7px);
    left: 20px;
    opacity: 1;
    transition: all .3s ease;
    color: #9e9e9e;
}
.notSelect{
    opacity: 0;
}

} 
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.navMov{
  
    align-items:flex-end;
    justify-content: flex-end;
}
.disabled{
   
    color: black i !important;
    background-color: #8f8c8c94  !important;
    opacity: 0.7  !important;

    
}
.disabled > .react-dropdown-select-dropdown-handle {
    display: none !important;
}
.outlineSelect > div  {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}
.outline{
    border:none !important;
    border-bottom: 1px solid #ccc !important;
}
.navMov {
    width: max-content !important;
    left: auto !important;
    right: 10px !important;
}

.mercedes{
  display:none !important;
}
.mercedes div{
  display:none !important;
}

@media only screen 
  and (min-device-width: 600px)
  and (max-device-width: 1024px)
  and (orientation: portrait)  {
.order_first{
      order: -1;
      margin-bottom: 20px;
}
}
@media only screen and (max-width: 600px) {
  .contrato{
    display: block !important;
  }
  .navMov{
   background-color: #2c2c2c;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
    right: 0 !important;
    
    button{
      background-color:transparent ;
      border-radius:0;
      border:none
    }
    i.material-icons {
    font-size: 28px;
    margin-left: 10px;
}

  }
 .floatingLabel {
      padding-right: 0 !important;
    width: 100% !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
}
.filter {
    margin-left: 15px;
}
.mov{
  padding-right: 0 !important;
}

}

.outlineSelect > div  {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

